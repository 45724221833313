<template>
  <div class="role-form">
    <h2 class="head-text">{{ headText }}</h2>
    <div>
      <md-tabs md-sync-route>
        <md-tab id="tab-home" :md-label="$t('Role')" :to="path" exact>
          <div class="field-content">
            <p>{{ $t('name') }}</p>
            <md-field>
              <md-input disabled v-model="role_name" placeholder="Select"></md-input>
            </md-field>
          </div>
          <div class="permissions-wrap">
            <h3>{{ $t('Role permissions') }}</h3>
            <div class="role-wrap" v-for="permission in permissions.data" :key="permission.id">
              <md-checkbox
                  v-model="selected_permissions"
                  :value="permission.id"
                  :class="setCheckedStyleAdmin(permission.id)"
                  :disabled="checkAdmin()"
              >
                {{ $t(permission.name_human) }}
              </md-checkbox>
            </div>
            <div>
              <Messages :messages="messages"></Messages>
              <Errors :errors="errors"></Errors>
            </div>
            <div class="action">
              <md-button class="md-dense save" v-on:click="saveRole">{{ $t('save') }}</md-button>
              <md-button class="md-raised cancel">
                <router-link to="/roles"><span>{{ $t('cancel') }}</span></router-link>
              </md-button>
            </div>
          </div>
        </md-tab>
        <md-tab id="tab-pages" :md-label="$t('Access rules')" to="#">
          {{ $t('Access rules') }}
          <p>{{ $t('accessRoleText') }}</p>
        </md-tab>
      </md-tabs>
    </div>
  </div>
</template>

<script>
import {errors} from "@/mixins/errors";
import {messages} from "@/mixins/messages";
import {printArray} from "@/mixins/printArray";
import Errors from "@/components/Errors";
import Messages from "@/components/Messages";
import { mapGetters } from "vuex";

export default {
  name: 'edit-or-create-role',
  mixins: [
    printArray,
    errors,
    messages,
  ],
  components: {
    Errors,
    Messages
  },
  data: () => ({
    role: '',
    role_name: '',
    permissions: {
      value: [],
      data: []
    },
    selected_permissions: []
  }),
  watch: {
    $route() {
      this.clearErrors()
      this.clearMessages()
    },
    userData: {
      deep: true,
      handler() {
        this.clearErrors()
        this.clearMessages()
        this.role_name = this.$t(this.role.name);
      }
    }
  },
  mounted() {
    this.$axios
        .get(process.env.VUE_APP_API_URL + "/permissions/list")
        .then(response => {
          this.permissions.data = response.data.data
        })
        .catch(error => console.log(error));

    let roleId = this.$route.params.id;

    if (roleId) {
      this.$axios
          .get(process.env.VUE_APP_API_URL + '/roles/' + roleId + '/get')
          .then(response => {
            this.role = response.data.data;
            this.role_name = this.$t(this.role.name)
            this.selected_permissions = this.role.permissions.map(x => x.id)
          })
          .catch(err => {
            this.setErrors(err.response.data.errors);
            this.$router.push({name: 'roles-list'});
          })
    }
  },
  computed: {
    ...mapGetters(['userData']),
    path() {
      let pathName;
      if (this.$route.name === 'roles-create') {
        pathName = "/roles/create"
      } else if (this.$route.name === 'roles-edit') {
        pathName = `/roles/edit/${this.$route.params.id}`
      }

      return pathName
    },
    createOrUpdate() {
      return (Object.keys(this.role).length !== 0) ? '/roles/' + this.role.id + '/update' : '/roles/create';
    },
    headText() {
      let headText;

      headText = this.$route.name === 'roles-create' ? 'Create Role' : this.$t('Edit Role');

      return headText
    }
  },
  methods: {
    saveRole() {
      let url = process.env.VUE_APP_API_URL + this.createOrUpdate;

      this.clearErrors()
      this.clearMessages()

      let data = {
        is_safety: 0,
        name: this.$t(this.role_name),
        permissions: this.selected_permissions
      };

      this.$axios
          .post(url, data)
          .then(response => {
            if (response.data.status === 'success') {
              this.addMessage(response.data.message);
            } else {
              this.setErrors(response.data.message);
            }
            this.$router.push({name: 'roles-list'});
          })
          .catch(err => {
            const error = this.$t(err.response.data.errors[0])
            this.setErrors([error]);
          })

    },

    checkAdmin() {
      if ((this.role_name == 'admin' || this.role_name == 'админ') && this.selected_permissions) {
        return true;
      }
    },

    setCheckedStyleAdmin(id) {
      return this.checkAdmin() && this.selected_permissions?.includes(id) ? 'checkedAdminRole' : ''
    }
  }
}
</script>

<style lang="scss">

.activePermissions {

}

.role-form {
  margin-left: 21px;

  p {
    margin: 0;
  }

  .head-text {
    font-weight: 900;
    font-size: 36px;
    line-height: 52px;
    color: #000000;
  }

  .action {
    display: flex;
    align-items: baseline;

    .save {
      color: white;
    }

    .cancel {
      margin-left: 58px;
      border-radius: 6px;
      width: 142px;
      height: 48px;
      box-shadow: none;
      border: 1px solid #CDD0D9;
    }

    .md-theme-default a:not(.md-button) {
      color: black !important;
    }

    span {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      padding: 12px 32px;
    }
  }

  .field-content {

    display: flex;
    align-items: center;

    p {
      margin: 0;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: right;
      color: #000000;
    }

    .md-field {
      width: 418px;
      margin-left: 15px;
      &:after {
        height: unset;
      }
    }

    .md-input {
      height: 48px;
      padding-left: 25px;
      border: 1px solid #CDD0D9;
      box-sizing: border-box;
      border-radius: 6px;
      background: #939393;
      opacity: .5;
      font-size: 16px;
    }
  }

  .permissions-wrap {
    h3 {
      font-weight: bold;
      font-size: 22px;
      line-height: 34px;
      color: #000000;
    }

    .md-checkbox.md-theme-default.md-checked .md-checkbox-container {
      background: #054F63;
      border: none !important;
      border-radius: 6px !important;
    }

    .checkedAdminRole > .md-checkbox-container {
      background: gray !important;
    }

    .md-checkbox.md-theme-default .md-checkbox-container {
      border-radius: 6px !important;
    }

    .md-checkbox .md-checkbox-container:after {
      top: 2px;
      left: 7px;
    }

    .md-dense {
      width: 142px;
      height: 48px;
      margin: 53px 0 0 0;
      background: #EC8B31;
      border-radius: 6px;
      color: white;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.md-tabs.md-theme-default .md-tabs-navigation {
  border-bottom: 2px solid grey;
}

.md-tabs.md-theme-default .md-tabs-indicator {
  background-color: #054F63 !important;
  top: 48px;
}

.md-tabs.md-theme-default .md-tabs-navigation .md-button {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.md-tabs.md-theme-default .md-tabs-navigation .md-button.md-active {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}
</style>